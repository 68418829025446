
<template>
  <div class="Manage_2">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect_top">
        <el-menu-item index="1">销售线索</el-menu-item>
        <el-menu-item index="2">客户管理</el-menu-item>
        <el-menu-item index="3">订单管理</el-menu-item>
        <el-menu-item index="4">合同管理</el-menu-item>
        <el-menu-item index="5">数据报表</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
    </div>
    <div class="tab_Bor"> 
      <div class="T1 bord">
        <el-tooltip class="item" effect="dark" content="客户的来源" placement="top">
          <span style="float:left;margin-right:10px">客户来源<i class="el-icon-question"></i>:</span>
        </el-tooltip>

        <div class="T2" style="margin-left:24px">
          <div class="Tuyiiu" @click="Activ(1, 0)" :class="0 == leads_source_value ? 'Actits' : ''">全部</div>
          <div @click="Activ(1 , item.value)" :class="item.value == leads_source_value ? 'Actits' : ''"
                       v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value">{{item.content}}</div>
          <div @click="AddXs(1)">管理客户来源</div>
        </div>
      </div>
      <div class="T1 bord">
        <el-tooltip class="item" effect="dark" content="客户的需求" placement="top">
          <span style="float:left;margin-right:10px">客户需求<i class="el-icon-question"></i>:</span>
        </el-tooltip>

        <div class="T2" style="margin-left:24px">
          <div class="Tuyiiu" @click="Activ(2, 0)" :class="0 == leads_demand_value ? 'Actits' : ''">全部</div>
          <div @click="Activ(2 , item.goods_id)" :class="item.goods_id == leads_demand_value ? 'Actits' : ''" 
              v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id">{{item.goods_name}}</div>
        </div>
      </div>
      <div class="T1 bord">
        <el-tooltip class="item" effect="dark" content="客户的价值" placement="top">
          <span style="float:left;margin-right:10px">客户价值<i class="el-icon-question"></i>:</span>
        </el-tooltip>

        <div class="T2" style="margin-left:24px">
          <div class="Tuyiiu" @click="Activ(3, 0)" :class="0 == leads_worth_value ? 'Actits' : ''">全部</div>
          <div @click="Activ(3 , item.value)" :class="item.value == leads_worth_value ? 'Actits' : ''" 
                        v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value">{{item.content}}</div>
          <div @click="AddXs(3)">管理客户类型</div>
        </div>
      </div>
      <div class="T1">
        <el-tooltip class="item" effect="dark" content="客户的标签" placement="top">
          <span style="float:left;margin-right:10px">客户标签<i class="el-icon-question"></i>:</span>
        </el-tooltip>

        <div class="T2" style="margin-left:24px">
          <div class="Tuyiiu" @click="Activ(4, 0)" :class="0 == leads_customer_tag_value ? 'Actits' : ''">全部</div>
          <div @click="Activ(4 , item.value)" :class="item.value == leads_customer_tag_value ? 'Actits' : ''" 
                        v-for="item in leads_customer_tag" :key="item.value" :label="item.content" :value="item.value">{{item.content}}</div>
          <div @click="AddXs(7)">管理客户标签</div>
        </div>
      </div>
      <div class="T1">
        <el-tooltip class="item" effect="dark" content="创建的时间" placement="top">
        <span style="float:left;margin-right:10px">创建时间<i class="el-icon-question"></i>:</span>
        </el-tooltip>

        <div style="float:left;" class="Topo Tuyiiu">
          <el-date-picker v-model="Months1" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          <span style="margin: 0 15px 0 15px;display: inline;">至</span>
          <el-date-picker v-model="Months2" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
        <div class="Actit" @click="AddXs(4)"  style="margin-left:30px;cursor: pointer;">查询</div>
        <div class="Actit dsdsdsdaaa" @click="AddXs(10)"  style="margin-left:30px;cursor: pointer;">重置</div>
        </div>
      </div>
    </div>
    <div class="Tables">
      <div class="T_sele dasdas">
          <el-select v-model="type_Self" placeholder="请选择线索" @change="Chang_ses">
              <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
      </div>
       <div class="Tab_T" @click="AddXs(5)" v-if="TextMoMo('customer:customer:add')"> <i class="el-icon-plus"></i>创建线索</div>
      <div class="Tab_T Tab_y" @click="export2Excel"  v-if="type_Self == 'common'"><img class="Img_ti" src="../../assets/img/daochu.png" alt="">导出</div>
      <div class="Tab_T Tab_y" @click="editVisible_DEX = true"  v-if="TextMoMo('customer:customer:import')"><img class="Img_ti" src="../../assets/img/daoru.png" alt="">导入</div>
      <div class="Tab_T Tab_y" @click="Add_Del(4)"
          v-if="TextMoMo('customer:customer:callback')"><img class="Img_ti" src="../../assets/img/tuihui.png" alt="">批量退回</div>
      <div class="Tab_T Tab_y"  @click="Add_Del(5)"  
        v-if="TextMoMo('customer:customer:claim')"><img class="Img_ti" src="../../assets/img/biaoji.png" alt="">批量认领</div>
      <div class="Tab_T Tab_y" style="border: 1px solid #FF4D4F;color:#FF4D4F" 
        v-if="TextMoMo('workbench:sales:middle:add')" @click="Add_Del(2)"> <i class="el-icon-delete"></i> 批量删除</div>
      <div style="margin-top:6px;cursor: pointer;float:left" @click="AddXs(6)" ><i class="el-icon-s-tools"></i></div>
        <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
        <div class="dsds">
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>
        <div v-if="type_Self == 'self'">
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            @row-click="handleRowClick"
            header-cell-class-name="table-header"
            @selection-change="handhange">
            <el-table-column
              type="selection"
              width="65"
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="65"
              align="center"></el-table-column>
            <el-table-column align="center" prop="username" label="姓名"></el-table-column>
            <el-table-column align="center" prop="mobile" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="source" label="客户来源"></el-table-column>
            <el-table-column align="center" prop="demand" label="需求项目">
                <template #default="scope" >
                  <div class="elelels" >
                    <span v-for="item in scope.row.goods" :key="item" :label="item" :value="item">{{item}}，</span>
                  </div>
                </template>          
            </el-table-column>
            <el-table-column align="center" prop="worth" label="客户价值"></el-table-column>
            <el-table-column align="center" prop="createtime" label="创建时间"></el-table-column>
            <el-table-column align="center" prop="updatetime" label="更新时间"></el-table-column>
            <el-table-column label="操作" align="center" width="300">
              <template #default="scope" >
                  <!-- <div class="el_butto" >
                    <el-button type="text"  v-if="TextMoMo('customer:customer:read')" @click="Handlbchak(2, scope.row)">查看</el-button>
                    <el-button type="text"  v-if="!TextMoMo('customer:customer:read')">查看</el-button>
                  </div> -->
                  <div class="el_butto" >
                    <el-button type="text"  v-if="TextMoMo('customer:customer:edit')" @click="Handlbchak(1, scope.row)">编辑</el-button>
                  </div>
                  <div class="el_butto " >
                    <el-button @click="HanEdit_SJ(scope.$index, scope.row)">升级</el-button>
                  </div>
                  <div class="el_butto" >
                    <el-button 
                    type="text"  v-if="TextMoMo('customer:customer:callback')"
                    @click="Handlbc_TH(scope.$index, scope.row)"
                    >退回</el-button>
                  </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text"  v-if="TextMoMo('customer:customer:del')"
                    class="red"
                    @click="HanDel(scope.$index, scope.row)"
                    >删除</el-button>
                </div>

              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>
        <div v-if="type_Self == 'common'">
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handhange">
            <el-table-column
              type="selection"
              width="65"
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="65"
              align="center"></el-table-column>
            <el-table-column prop="username" label="姓名" width="130"></el-table-column>
            <el-table-column prop="mobile" label="联系方式" width="150"></el-table-column>
            <el-table-column prop="source" label="客户来源" width="150"></el-table-column>
            <el-table-column prop="demand" label="需求项目" width="160">
                <template #default="scope" >
                  <div class="elelels" >
                    <span v-for="item in scope.row.demand" :key="item" :label="item" :value="item">{{item}}<span v-if="scope.row.demand.length > 1">,</span></span>
                  </div>
                </template>          
            </el-table-column>
            <el-table-column prop="worth" label="客户价值" width="150"></el-table-column>
            <el-table-column prop="from.realname" label="创建人" width="150"></el-table-column>
            <el-table-column prop="createtime" label="创建时间" width="200"></el-table-column>
            <el-table-column label="操作" align="center" >
              <template #default="scope" >
                  <div class="el_butto" style="margin-right:10px">
                    <el-button
                    type="text"
                    @click="HanEdit_SL(scope.$index, scope.row)"
                    >认领</el-button>
                  </div>
                <div class="el_butto rere">
                    <el-button
                    type="text"
                    class="red"
                    @click="HanDel(scope.$index, scope.row)"
                    >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>
    </div>

    <!--全部线索管理-->
    <div ref="rightPanel" v-if="show4" class="rightPanel-container show">
      <div class="rightPanel-background"/>
      <div style="height:1100px;width: 345px;position: fixed;top: 0;right: 0;z-index:999;" >
        <el-scrollbar style="height:100%">
          <div class="rightPanel" style="height: 830px;padding-bottom: 120px;">
            <div class="Rig_1">全部线索管理<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div class="Rig_A">
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:left;margin-bottom:8px;width:160px" >公海池认领量（条）</div>
                <div class="Rig_Rrr" @click="NNNNN(1)">内容管理</div>
                <el-select v-model="ConfigList.claim_day" placeholder="每人每天认领线索上限">
                    <el-option v-for="item in Config1" :key="item.value" :label="item.content" :value="item.content"></el-option>
                </el-select>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:left;margin-bottom:10px;width:160px">单次客户认领量（条）</div>
                <div class="Rig_Rrr" @click="NNNNN(2)">内容管理</div>
                <el-select v-model="ConfigList.claim_num" placeholder="每人每天认领线索上限">
                    <el-option v-for="item in Config2" :key="item.value" :label="item.content" :value="item.content"></el-option>
                </el-select>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:left;margin-bottom:10px;width:160px">进程更新对低频（天）</div>
                <div class="Rig_Rrr" @click="NNNNN(3)">内容管理</div>
                <el-select v-model="ConfigList.claim_one" placeholder="低于设置时间将回收销售线索">
                    <el-option v-for="item in Config3" :key="item.value" :label="item.content" :value="item.content"></el-option>
                </el-select>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:left;margin-bottom:10px;width:160px">每周数据导出频率（次）</div>
                <div class="Rig_Rrr" @click="NNNNN(4)">内容管理</div>
                <el-select v-model="ConfigList.export_one" placeholder="每周每人可以带出数据次数">
                    <el-option v-for="item in Config4" :key="item.value" :label="item.content" :value="item.content"></el-option>
                </el-select>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:left;margin-bottom:10px;width:160px">每次数据导出数量（条）</div>
                <div class="Rig_Rrr" @click="NNNNN(5)">内容管理</div>
                <el-select v-model="ConfigList.export_week" placeholder="每人每次可以导出数量的上限">
                    <el-option v-for="item in Config5" :key="item.value" :label="item.content" :value="item.content"></el-option>
                </el-select>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:left;margin-bottom:10px;width:160px">认领权限设置</div>
                <div class="Rig_Rrr" @click="NNNNN(6)">内容管理</div>
                <el-select v-model="ConfigList.flow_min" placeholder="同一人认领同一线索次数">
                    <el-option v-for="item in Config6" :key="item.value" :label="item.content" :value="item.content"></el-option>
                </el-select>
                
              </div>
              
              <div class="BCSZ" style="margin-right:24px" @click="Btn_Xsxs">确定</div>
              <div class="BCSZ" @click="show4 = false" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">取消</div>
              
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!--全部线索管理-->

    <!--添加线索来源-->
    <el-dialog title="添加线索来源" v-model="dialogVisible3" width="36%">
      <div class="Tabbl">
        <el-table
        :data="Categ"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>

      <div class="Y_ids" @click="Add_leibie"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer" style="margin-bottom:15px">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN()">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--添加线索来源-->

    <!--全部线索类型管理-->
    <el-dialog title="全部线索类型管理" v-model="dialogVisible2" width="36%">
      <div class="Tabbl">
        <el-table
        :data="Categ2"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input type="number" :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete2(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>

      <div class="Y_ids" @click="Add_leibie2"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN2()">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--全部线索类型管理-->

    <!--全部线索类型管理-->
    <el-dialog title="服务对象内容管理" v-model="dialogVisible4" width="36%">
      <div class="Tabbl">
        <el-table
        :data="Categ3"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input type="text" :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete2(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>

      <div class="Y_ids" @click="Add_leibie3"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN1()">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--全部线索类型管理-->

    <!-- 创建线索 -->
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="width: 656px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height: 830px;padding-bottom: 120px;">
                <div class="Rig_1" v-if="chakan111 == 1">创建线索<i class="el-icon-close" @click="Close"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_1" v-if="chakan111 == 0">编辑线索<i class="el-icon-close" @click="Close"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div style="margin:0 0 18px 24px " v-if="chakan111 == 0">
                  <el-menu
                    :default-active="acndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect">
                    <el-menu-item index="0">销售线索</el-menu-item>
                    <el-menu-item index="1">客户管理</el-menu-item>
                    <el-menu-item index="2">跟进记录</el-menu-item>
                  </el-menu> 
                </div>
                <div class="Rig_A" v-if="info_Show == 0">
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>姓名：</div>
                                <div class="Rtty">
                                <el-input v-model="Valuea_Xiansuo.username" placeholder="" disabled class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 联系方式：</div>
                                <div class="Rtty">
                                <el-input v-model="Valuea_Xiansuo.mobile" placeholder="" disabled class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 需求来源：</div>
                                <el-select v-model="Valuea_Xiansuo.source_id" placeholder="" disabled>
                                  <el-option v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 需求价值：</div>
                                <el-select v-model="Valuea_Xiansuo.worth_id" placeholder="" disabled>
                                    <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <div class="Rig_D" style="width:95%" >
                      <div class="Rig_Ddd" >需求项目：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="valueA_3" multiple placeholder=""  disabled>
                            <el-option v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
                        </el-select>
                      </div>
                    </div> 
                    <div class="Rig_D" style="width:100%" >
                        <div class="Rig_Ddd" >描述（选填）：</div>
                        <div class="Rtty">
                            <el-input type="textarea" style="" v-model="Valuea_Xiansuo.description" placeholder="" disabled ></el-input>           
                        </div>
                    </div>
                    <div>
                      <div class="Rig_Ddd" >图片线索（选填）</div>
                      <div class="Rtty" style="clear:both">
                        <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                      </div>
                    </div>
                    <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:30px" @click="show1 = false">取消</div>
                </div>
                <div class="Rig_A" v-if="info_Show == 1">
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>姓名：</div>
                                   <div class="Rtty"><el-input style="" v-model="valueA_input1" placeholder="请输入姓名" ></el-input>  </div>
                                  
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width: 138px;"><span style="color:#FF4D4F">* </span>性别：</div>
                                <el-select v-model="valueA_input2" placeholder="请选择性别">
                                    <el-option v-for="item in SEX" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 联系方式：</div>
                                <div class="Rtty">
                                <el-input v-model="valueA_input3" placeholder="请输入联系方式" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>

                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px">微信号（选填）：</div>
                                <div class="Rtty">
                                <el-input v-model="valueA_input4" placeholder="请输入微信号" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width: 138px;">工作单位（选填）：</div>
                                <div class="Rtty">
                                <el-input v-model="valueA_input5" placeholder="请输入姓名" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin:25px 0 20px 0;font-weight: bold;font-size:14px">来源信息</div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 客户来源：</div>
                                <el-select v-model="valueA_1" placeholder="请选择需求来源">
                                  <el-option v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 客户价值：</div>
                                <el-select v-model="valueA_2" placeholder="请选择需求价值">
                                    <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 办理时间（选填）：</div>
                                <el-date-picker v-model="valueA_Months" type="date" placeholder="选择时间" :clearable="false"></el-date-picker>
                            </div>
                        </div>
                        
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 服务对象：</div>
                                <div class="Rig_Rrr" @click="AddXs(11)">内容管理</div>
                                <el-select v-model="valueA_input6" placeholder="请选择需求价值">
                                    <el-option v-for="item in leads_customer_service" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <div class="Rig_D" style="width:95%">
                      <div class="Rig_Ddd" >客户标签（可多选）：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="valueA_sele1" multiple placeholder="请选择需求项目" @change="changeRTY" >
                            <el-option v-for="item in leads_customer_tag" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                      </div>
                    </div> 
                    <div class="Rig_D" style="width:95%" >
                      <div class="Rig_Ddd" >需求项目（可多选）：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="valueA_sele2" multiple placeholder="请选择需求项目" @change="changeRTY" >
                            <el-option v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
                        </el-select>
                      </div>
                    </div> 

                    <div class="Rig_D" style="width: 100%;">
                        <div class="Rig_Ddd" >描述（选填）：</div>
                        <div class="Rtty">
                            <el-input type="textarea" v-model="valueA_textarea" placeholder="可以将线索用户的一些具体特征或者条件如实的写下来，方便员工更好的了解此线索" ></el-input>           
                        </div>
                    </div>
                    <div>
                      <div class="Rig_Ddd" >图片线索（选填）</div>
                      <div class="Rtty" style="clear:both">
                  
                        <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                        <div style="margin-top:20px">
                          <el-upload
                            :action="upload"
                            :headers="heads"
                            list-type="picture-card"
                            :on-success="UP_Success"
                            :on-remove="HAndemove"
                            :on-preview="handlePictureCardPreview">
                            <i class="el-icon-plus"></i>
                            <div class="el_asff">上传头像</div>
                          </el-upload>
                        </div>
                      </div>
                    </div>
                    <div class="BCSZ" style="margin-right:24px;" @click="Btn_cj(1)" v-if="chakan111 == 1">保存设置</div>
                    <div class="BCSZ" style="margin-right:24px;" @click="Btn_cj(0)" v-if="chakan111 == 0">保存设置</div>
                    <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:20px" @click="show1 = false">取消</div>
                </div>
                <div v-if="info_Show == 2">
                    <div class="Rig_A">
                      <div class="Rig_D Tigtgg R_texxt" >
                        <div class="TRRE">
                          <div style="margin:8px 0 9px 0;font-size: 14px;color: #333333;">全部进程信息</div>
                            <div style="margin:20px 24px 0 1px;" v-if="Flow_list.length != 0">
                                <el-scrollbar height="100%">
                                  <div id="time-line">
                                    <div class="item" v-for="item in Flow_list" :key="item.index">
                                      <div class="ytyt">
                                        <div class="item-index">{{ item.createtime }}</div>
                                        <div class="item-content">{{ item.info }}</div>
                                        <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                                      </div>
                                    </div>
                                  </div>
                                </el-scrollbar>
                          </div>
                        </div>
                        <div class="Rig_D" style="position: relative;width:105%">
                            <div class="Rig_Ddd" style="margin-bottom:9px;margin-top:30px"> <span style="color:#FF4D4F">*</span> 文字记录</div>
                            <div class="Rtty">
                                <el-input 
                                type="textarea" v-model="Info_desc" 
                                placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率
① 选择常用语更新记录
② 手动输入文字信息"></el-input>           
                            </div>     
                          <div class="Rtty Riuo fdgfg" style="clear:both;">
                            <div style="margin:4px 23px 0 0;float:right">
                                <el-checkbox v-model="valueA_input1" label="同步给客户"></el-checkbox>
                            </div>
                          </div>
                        </div>  
                        <div>
                          <div class="Rig_Ddd" style="margin:28px 0 9px 0">图片线索（选填）</div>
                          <div class="Rtty TUUu" style="clear:both">
                              <div style="margin-top:20px">
                                <el-upload
                                  :action="upload"
                                  :headers="heads"
                                  list-type="picture-card"
                                  :on-success="UP_Success2"
                                  :on-remove="HAndemove2"
                                  :on-preview="handlePictureCardPreview">
                                  <i class="el-icon-plus"></i>
                                  <div class="el_asff">上传图片</div>
                                </el-upload>
                              </div>
                          </div>
                        </div>
                        <div class="Rtty">
                            <div class="BCSZ" style="margin-right:24px;margin-bottom:0;" @click="Btn_dsa">保存设置</div>
                            <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-bottom:0;">取  消</div>
                        </div>
                      </div> 

                    </div>
                
                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!-- 创建线索 -->

    <!-- 创建线索 -->
    <!-- <div ref="rightPanel" v-if="show11" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="width: 656px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height: 830px;padding-bottom: 120px;">
                <div class="Rig_1">查看线索<i class="el-icon-close" @click="show11 = false"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>

                <div class="Rig_A">
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>姓名：</div>
                                   <div class="Rtty"><el-input style="" v-model="valueA_input1" placeholder="请输入姓名" disabled></el-input>  </div>
                                  
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width: 138px;"><span style="color:#FF4D4F">* </span>性别：</div>
                                <el-select v-model="valueA_input2" placeholder="请选择性别" disabled>
                                    <el-option v-for="item in SEX" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 联系方式：</div>
                                <div class="Rtty">
                                <el-input v-model="valueA_input3" placeholder="请输入联系方式" class="El_ii" disabled></el-input>
                                </div>
                            </div>
                        </div>

                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px">微信号（选填）：</div>
                                <div class="Rtty">
                                <el-input v-model="valueA_input4" placeholder="请输入微信号" class="El_ii" disabled></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width: 138px;">工作单位（选填）：</div>
                                <div class="Rtty">
                                <el-input v-model="valueA_input5" placeholder="请输入姓名" class="El_ii" disabled></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin:25px 0 20px 0;font-weight: bold;font-size:14px">来源信息</div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 客户来源：</div>
                                <el-select v-model="valueA_1" placeholder="请选择需求来源" disabled>
                                  <el-option v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 客户价值：</div>
                                <el-select v-model="valueA_2" placeholder="请选择需求价值" disabled>
                                    <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 办理时间（选填）：</div>
                                <el-date-picker v-model="valueA_Months" type="date" placeholder="选择时间" :clearable="false" disabled></el-date-picker>
                            </div>
                        </div>
                        
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 服务对象：</div>
                                <div class="Rig_Rrr" @click="AddXs(11)">内容管理</div>
                                <el-select v-model="valueA_input6" placeholder="请选择需求价值" disabled>
                                    <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <div class="Rig_D Tigtgg" >
                      <div class="Rig_Ddd" >客户标签（可多选）：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="valueA_sele1" multiple placeholder="请选择需求项目" @change="changeRTY" disabled >
                            <el-option v-for="item in leads_customer_tag" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                      </div>
                    </div> 
                    <div class="Rig_D Tigtgg" >
                      <div class="Rig_Ddd" >需求项目（可多选）：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="valueA_sele2" multiple placeholder="请选择需求项目" @change="changeRTY" disabled >
                            <el-option v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
                        </el-select>
                      </div>
                    </div> 

                    <div class="Rig_D">
                        <div class="Rig_Ddd" >描述（选填）：</div>
                        <div class="Rtty">
                            <el-input type="textarea" style="" v-model="valueA_textarea" disabled placeholder="可以将线索用户的一些具体特征或者条件如实的写下来，方便员工更好的了解此线索" ></el-input>           
                        </div>
                    </div>
                    <div>
                      <div class="Rig_Ddd" >图片线索（选填）</div>
                      <div class="Rtty" style="clear:both">
                  
                        <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                      </div>
                    </div>
                    <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:20px" @click="show11 = false">取消</div>
                </div>

              </div>
            </el-scrollbar>
          </div>
    </div> -->
    <!-- 创建线索 -->


    <!-- 导入 -->
    <el-dialog title="导入文件" v-model="editVisible_DEX" width="31%" class="">
        <el-form ref="form" label-width="120px">
          <div class="DDGGD">
          <el-form-item label="模板：">
             <el-button type="primary"> <a style="color:#fff" href="./w3school_logo2.xlsx" download="w3school_logo2.xlsx">下载模板</a></el-button>
          </el-form-item>
          <el-form-item label="选择导入文件：" style="margin-bottom:5px">
            <div class="el-form-item__content">
              <el-upload
                  accept=".xls,.xlsx"
                  :action="upload"
                  :headers="heads"
    
                  :multiple="false"
                  :file-list="fileList"
                  :on-success="UP_Success3"
                  :on-remove="fileRemove">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
              </el-upload>

          </div>
          </el-form-item>
          <el-form-item label="可见员工：">
            <div class="Rtty Riiiu">
              <el-select v-model="File_Yid" multiple placeholder="请选择可见员工">
                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="可见部门：">
            <div class="Rtty Riiiu">
              <el-cascader
                :options="DementList"
                v-model="File_Bid"
                :props="{ multiple: true, checkStrictly: true, label : 'name', value: 'id'}"
                @change="Handmuiksd2"
                clearable
              ></el-cascader>
            </div>
          </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible_DEX = false" style="margin-right:15px">取 消</el-button>
            <el-button type="primary" @click="File_Sure()">确 认</el-button>
          </span>
        </template>
      

    </el-dialog>
    <!-- 导入 -->

    <!-- 身份升级 -->
    <el-dialog title="身份升级" v-model="editVisible" width="30%" class="">
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible = false" style="margin-right:15px">取 消</el-button>
            <el-button type="primary" @click="saveEdit()">确 认</el-button>
          </span>
        </template>
      

    </el-dialog>
    <!-- 身份升级 -->
  </div>
</template>

<script>
import axios from 'axios' 
import  TextMoth  from "../../components/utils.vue";
export default {
  name: "basetable",
  data() {
    return {
      TA_name1:'',
      TA_name1_Arr:[],
      TA_name2:'销售线索',
      TA_name3:'',
      Show:false,
      Inpup:'',
      reverse: true,

      Flow_list:[],
      info_Show:0 ,
      checked_kehu:false,
      activeIndex: '2',
      acndex:'0',
      dialogImageUrl: '',
      radio:'1',

      show1:false,
      show11:false,
      show4: false,
      dialogVisible2: false,  
      dialogVisible3: false,  
      dialogVisible4: false,

      
      multipleSelection_id:null,   //勾选的信息
      multipleSelection:[],

      tableData: [],
      total1:0,
      total2:0,
      currentPage1:0,
      currentPage2:0,

      idW:0,
      idY:0,
      idG:0,

      Categ:[],
      Categ2:[],
      Categ3:[],
      input_seach:'',
      type_Self: 'self',
      dialogVisible3_e:0,
      dialogVisible2_e:0,

      Config1:[],
      Config2:[],
      Config3:[],
      Config4:[],
      Config5:[],
      Config6:[],
      
      leads_source:[],  //线索来源
      leads_source_W:[],  //线索来源
      leads_source_value:0,

      leads_demand:[],  //线索需求
      leads_demand_W:[],  //线索需求
      leads_demand_value:0,  //线索需求

      leads_worth:[],  //线索价值
      leads_worth_W:[],  //线索价值
      leads_worth_value:0,  //线索价值

      leads_customer_tag:[],  //线索价值
      leads_customer_tag_W:[],  //线索价值
      leads_customer_tag_value:0,  //线索价值

      leads_customer_service:[],            //服务对象
      leads_customer_service_W:[],
      leads_customer_service_value:0,

      Months1:'',
      Months2:'',

      se_id:0,
      wo_id:0,
      de_id:0,
      tag_id:0,

      SEX:[{name:'男',id:'1'},{name:'女',id:'2'}],
      valueA_1:'',
      valueA_2:'',
      valueA_3:'',
      valueA_4:'',
      valueA_5: [],
      Case_attse:[],  //可见部门
      chakan111:0,

      valueA_input1:'',
      valueA_input2:'',
      valueA_input3:'',
      valueA_input4:'',
      valueA_input5:'',
      valueA_input6:'',
      valueA_Months:'',      //办理时间
      valueA_textarea:'',
      valueA_sele1:[],     //客户标签
      valueA_sele2:[],     //需求项目

      Row_Eurl:[],          //图片
      Row_Eurl2:[],          //图片
      Info_desc:'',
      Claim:{},
      KH_ID:0,

      Valuea_Xiansuo:{},
      valueA_3:'',

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},

      MMD:[{id:'self',name:'我的客户'},{id:'common',name:'公海客户'}],
      MMD_detail:{},
      editVisible:false,
      Customer_worth:[],
      Customer_Self:'',
      StaFFList:[],    ///////////// 全部员工列表
      DementList:[],   ////////////  全部部门列表

      editVisible_DEX:false,
      File:'',
      File_Yid:[],
      File_Bid:[],
      fileList:[],

      fForm:{},
      ConfigList:{},
      TA_name1:'',
      TA_name1_Arr:'',
    };
  },
  mounted() {
    var that = this

    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios.get('/admin/ajax/staffList',{   // 全部员工列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
              that.StaFFList = res.data.data
          }
    })  
    axios.get('/admin/company/department/index',{   // 全部部门列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DementList = res.data.data.rows
        }
    })
    axios({ method: 'post', url: '/admin/ajax/goodsList', 
        }).then(function (res) {
          if(res.data.code == 0) {
          } else {
            that.leads_demand = res.data.data
            that.leads_demand_W = res.data.data.slice()   //复制一个数组
            that.leads_demand_value = 0
          }
    })
    axios.get('/admin/customer/customer/statisticsColumns',{ 
      }).then(function (res) {
        if (res.data.code == 401) {
            // that.$router.push('/Login');
        } else{
          that.leads_source = res.data.data.customer_source              //客户来源
          that.leads_source_W = res.data.data.customer_source.slice()
          that.leads_source_value = 0

          that.leads_worth = res.data.data.customer_worth                 //客户价值
          that.leads_worth_W = res.data.data.customer_worth.slice()
          that.leads_worth_value = 0

          that.leads_customer_tag = res.data.data.customer_tag            //客户标签
          that.leads_customer_tag_W = res.data.data.customer_tag.slice()
          that.leads_customer_tag_value = 0

          that.leads_customer_service = res.data.data.customer_service            //服务对象
          that.leads_customer_service_W = res.data.data.customer_service.slice()
          that.leads_customer_service_value = 0
      }
    })  
    that.AAAAA() 
    axios.get('/admin/customer/config/index',{   // 全部部门列表
            params:{
              type: 'leads'
            }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.ConfigList = res.data.data.config
        }
    })  
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleRowClick(row, column, event){
          this.$refs.multipleTable.toggleRowSelection(row);
    },
    export2Excel() {                                            // 导出
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../excel/Export2Excel');
        const tHeader = ['姓名', '联系方式', '客户来源', '需求项目' , '客户价值', '创建人', '创建时间'];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ['username', 'mobile', 'source', 'demand', 'worth', 'from.realname', 'createtime'];
        // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData;  //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '客户管理');
      })
    },
    HanEdit_SJ(e, data) {              //点击身份升级升级
      var that = this
      that.editVisible = true
      that.TA_name1_Arr = data
    },
    saveEdit(e) {                 //身份升级确认
      var that = this
      that.$router.push({name: 'Manage_3', params: { id: that.TA_name1_Arr.id }});
       
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    beforeUpload(file) {                             // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
        //文件类型
        const isType = file.type === 'application/vnd.ms-excel'
        const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const fileType = isType || isTypeComputer
        if(!fileType) {
            this.$message.error('上传文件只能是xls/xlsx格式！')
        }
        // 文件大小限制为10M
        const fileLimit = file.size / 1024 / 1024 < 10;
        if(!fileLimit) {
            this.$message.error('上传文件大小不超过10M！');
        }
        return fileType && fileLimit
    },
    File_Sure(e) {
      var that = this
      var arr = []
      var acc = []
      axios({
          method: 'post',
          url: '/admin/customer/customer/import', 
          data: {
            file:that.File,
            power:{
              staff_ids:that.File_Yid,
              department_ids:that.valueA_5
            }
          },
        }).then(function (res) {

          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
              that.$message.success(res.data.msg);
              that.editVisible_DEX = false
          }
        })       
    },
    UP_Success3(e) {                //图片上传成功
      var that = this
      console.log(e)
      that.File = e.data.url
      that.aTY = 0
      console.log(that.File)
    },
    fileRemove(file, fileList) {                    // 移除选择的文件
        if(fileList.length < 1) {
            this.uploadDisabled = true //未选择文件则禁用上传按钮
        }
        console.log(file)
    },
    changeRTY(e) {
      var that = this
      console.log(e)
      console.log(that.valueA_3)
    },
    Handlbchak(e,data) {
      var that = this
      that.chakan111 = 0
      that.KH_ID = data.id
      that.info_Show = 1
      that.acndex = 1
      if (e == 1) {
        that.show1 = true
      } else if (e == 2) {
        that.show11 = true
      }
      axios.get('/admin/customer/customer/read',{   // 线索详情
            params:{
              id: data.id
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else {
            that.valueA_input1 = res.data.data.row.username
            that.valueA_input2 = res.data.data.row.sex
            that.valueA_input3 = res.data.data.row.mobile
            that.valueA_input4 = res.data.data.row.weixin
            that.valueA_input5 = res.data.data.row.company
            that.valueA_input6 = res.data.data.row.service_id                 // 服务对象
            that.valueA_1 = res.data.data.row.source_id                       // 客户来源
            that.valueA_2 = res.data.data.row.worth_id                        // 客户价值
            that.valueA_Months = res.data.data.row.expect_time                // 办理时间
            // that.valueA_sele1 = res.data.data.row.tag_ids                     //客户标签
            // that.valueA_sele2 = res.data.data.row.demand_ids                     //需求项目
            that.valueA_textarea = res.data.data.row.description

            var acc = []
            var add = []

            var arr1 = res.data.data.row.tag_obj
            var arr2 = res.data.data.row.demand_obj
            for (let i = 0; i < arr1.length; i++) {
              acc.push(parseInt(arr1[i]));
            }
            console.log(acc)
            for (let i = 0; i < arr2.length; i++) {
              add.push(parseInt(arr2[i]));
            }
            that.valueA_sele1 = acc
            that.valueA_sele2 = add
            that.full_avatar = res.data.data.row.imgs_url
            that.Row_Eurl = res.data.data.row.images
          }
      })
      axios.get('/admin/customer/flow/read',{   // 线索 进程信息
            params:{
              customer_id: data.id
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else { 
            that.Flow_list = res.data.data.flow_list

          }
      }) 
    },
    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.show1 = false;
      this.show4 = false;
    },
    TJ2() {
      this.dialogVisible7 = true;
    },

    submitUpload() {
      this.$refs.upload.submit();
    },
    Btn_dsa() {                     //  进程提交
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/flow/add', 
          data: {
            row:{
              customer_id: that.KH_ID, //客户ID，客户管理时必填
              info: that.Info_desc, //进程的信息
              type: "customer", //类型:leads=线索,customer=客户,order=订单,contract=合同
              source_id: that.KH_ID, //当前类型所在列表ID
              images: that.Row_Eurl2
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
              
            that.$message.success(res.data.msg);
            axios.get('/admin/customer/flow/read',{   // 线索 进程信息
              params:{
                customer_id: that.KH_ID
              }
              }).then(function (res) {
                if (res.data.code == 0) {
                  alert('请求失败');  
                } else { 
                  that.Flow_list = res.data.data.flow_list
                  that.Info_desc = ''
                }
            }) 
          }
          
        })       
    },
    Btn_Xsxs(e) {                   //全部线索管理 确认
      var that = this
        axios({
          method: 'post',
          url: '/admin/customer/config/edit', 
          data: {
            row:{
              leads:that.ConfigList
            }

          },
        }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.show4 = false
                that.AAAAA()
              }
        })      
    },
    AddXs(e) {     // 点击全部线索类型
      var that = this
       that.dialogVisible3_e = e
       let y1 =''
       let y2 =''
       
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       }
       if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }
      
      if (e == 1) {
        that.dialogVisible3 = true
        that.Categ = that.leads_source_W
      } else if (e == 2) {
        that.dialogVisible3 = true
        that.Categ = that.leads_demand_W
      } else if (e == 3) {
        that.dialogVisible3 = true
        that.Categ = that.leads_worth_W
      } else if (e == 4) {
        // that.dialogVisible3 = true
        axios({
          method: 'post',
          url: '/admin/customer/customer/index', 
          data: {
            page: 1,
            offset:0,
            limit:8,
            type: that.type_Self,
            filter:{
              source_id: that.se_id,
              worth_id: that.wo_id,
              demand_id: that.de_id,
              tag_id: that.tag_id,
              time:{
                min:y1,
                max:y2,
              },
              keyword:that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })
      } else if (e == 5) {
          that.chakan111 = 1
          that.info_Show = 1
          that.show1 = true
          that.valueA_1='',
          that.valueA_2='',
          that.valueA_3='',
          that.valueA_input1='',
          that.valueA_input2='',
          that.valueA_input3='',
          that.valueA_input4='',
          that.valueA_input5='',
          that.valueA_input6='',
          that.valueA_Months='',
          that.valueA_textarea='',
          that.valueA_sele1=[],
          that.valueA_sele2=[],
          that.Row_Eurl=[]      
          that.full_avatar=[]      

      } else if (e == 6) {
        that.show4 = true;
        that.BBBBB()
      } else if (e == 7) {
        that.dialogVisible3 = true
        that.Categ = that.leads_customer_tag
      } else if (e == 10) {
        that.Months1 = ''
        that.Months2 = ''
      } else if (e == 11) {
        that.dialogVisible4 = true
        that.Categ3 = that.leads_customer_service_W
      }
    },
    NNNNN(e){         //内容管理
      var that = this
      that.dialogVisible2 = true
      that.dialogVisible2_e = e
      
      axios.get('/admin/customer/config/statisticsColumns',{   // 设置选项 
            params:{
              type: 'customer'
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
              that.Claim = res.data.data
              if (e == 1) {
                that.Categ2 = res.data.data.claim_day
                that.Config1 = res.data.data.claim_day
              } else if(e == 2) {
                that.Categ2 = res.data.data.claim_one
                that.Config2 = res.data.data.claim_one
              } else if(e == 3) {
                that.Categ2 = res.data.data.flow_min
                that.Config3 = res.data.data.flow_min
              } else if(e == 4) {
                that.Categ2 = res.data.data.export_week
                that.Config4 = res.data.data.export_week
              } else if(e == 5) {
                that.Categ2 = res.data.data.export_one
                that.Config5 = res.data.data.export_one
              } else if(e == 6) {
                that.Categ2 = res.data.data.claim_num
                that.Config6 = res.data.data.claim_num
              }
          }
      })  

    },
    Activ(e, id) {
      console.log(e)
      console.log(id)
      var that = this
      if (e == 1) {
        that.leads_source_value = id
        that.se_id = id
      } else if (e == 2) {
        that.leads_demand_value = id
        that.wo_id = id
      } else if (e == 3) {
        that.de_id = id
        that.leads_worth_value = id
      } else if (e == 4) {
        that.tag_id =id
        that.leads_customer_tag_value = id
      }
       let y1 =''
       let y2 =''
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       }
       if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }
      axios({
        method: 'post',
        url: '/admin/customer/customer/index', 
        data: {
          page: 1,
          offset:0,
          limit:8,
          type: that.type_Self,
          filter:{
            source_id: that.se_id,
            worth_id: that.de_id,
            goods_id: that.wo_id,
            tag_id: that.tag_id,
            time:{
              min:y1,
              max:y2,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
       that.tableData = res.data.data.rows
       that.total1 = res.data.data.total
      }) 
    },
    Add_leibie() {            //创建导航类别
      var that = this
      var ATT = that.Categ
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ = ATT
    },  
    Add_leibie2() {            //创建导航类别    --------------全部线索管理
      var that = this
      var ATT = that.Categ2
      var srt = {
        content: "10",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ2 = ATT
    }, 
    Add_leibie3() {            //创建导航类别    --------------全部线索管理
      var that = this
      var ATT = that.Categ3
      var srt = {
        content: "10",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ3 = ATT
    }, 
    NabCH_BTN1() {             // 导航确认
        var that = this 
        var ATT = that.Categ3
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_service", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible4 = false
                that.Columns()
              }
          })  

    },
    NabCH_BTN() {             // 导航确认
        var that = this 
        var ATT = that.Categ
        if (that.dialogVisible3_e == 1) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_source", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })  
        } else if(that.dialogVisible3_e == 2) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_demand", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })            

        } else if(that.dialogVisible3_e == 3) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_worth", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })  
        } else if(that.dialogVisible3_e == 7) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_tag", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })  
        }
     
    },
    NabCH_BTN2() {             // 导航确认         --------------全部线索管理
        var that = this 
        var ATT = that.Categ2
        if (that.dialogVisible2_e == 1) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_claim_day", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 2) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_claim_one", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 3) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_flow_min", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 4) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_export_week", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 5) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_export_one", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 6) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "customer_claim_num", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        }
     
    },
    haeDete(index, row) {     // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.Categ
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ = ATT
      }
    },
    haeDete2(index, row) {     // 删除导航类别   --------------全部线索管理
      var that = this
      console.log(row)
      var ATT = that.Categ2
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ2 = ATT
      }
    },
    Chang_ses(e) {           // 切换我的线索
      var that = this
      that.type_Self = e
      that.multipleSelection_id = null
      that.multipleSelection = []
      console.log(e)     
             let y1 =''
       let y2 =''
       
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       }
       if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }
 
      axios({
        method: 'post',
        url: '/admin/customer/customer/index', 
        data: {
          page: 1,
          offset:0,
          limit:8,
          type: e,
          filter:{
            source_id: that.se_id,
            worth_id: that.wo_id,
            demand_id: that.de_id,
            tag_id: that.tag_id,
            time:{
              min:y1,
              max:y2,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })         
    },
    inpu_sech() {             // 搜索
      var that = this
             let y1 =''
       let y2 =''
       
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       }
       if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }

      axios({
        method: 'post',
        url: '/admin/customer/customer/index', 
        data: {
          page: 1,
          offset:0,
          limit:8,
          type: that.type_Self,
          filter:{
            source_id: that.se_id,
            worth_id: that.wo_id,
            demand_id: that.de_id,
            tag_id: that.tag_id,
            time:{
              min:y1,
              max:y2,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })       
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
    },
    handleSelect_top (e) {
      if (e == 1) {
        this.$router.push('/Workbench/Manage');
      }else if (e == 2) {
        this.$router.push('/Workbench/Manage_2');
      } else if (e == 3) {
        this.$router.push('/Workbench/Manage_3');
      } else if (e == 4) {
        this.$router.push('/Workbench/Manage_4');
      }else if (e == 5) {
        this.$router.push('/Workbench/Manage_5');
      }
    },
    handleSelect(key, keyPath) {
      console.log(key);
      console.log(keyPath);
      if (key == 0) {
        this.info_Show = 0;
      } else if (key == 1){
        this.info_Show = 1
          axios.get('/admin/customer/leads/read',{
                params:{
                  id: this.Customer_id
                }
            }).then(function (res) {
              if (res.data.code == 0) {
                // alert('请求失败');  
              } else { 
                
                that.Valuea_Xiansuo = res.data.data.row

                var arr = parseInt(res.data.data.row.demand_obj)
                var acc = []
                acc.push(arr)
                that.valueA_3 = acc
                that.full_avatar = res.data.data.row.imgs_url
                that.Row_Eurl = res.data.data.row.images
              }
          })
      } else  {
        this.info_Show = 2
      }
    },
    changeSwitch(data,b,index) {      // 是否启用
      var that = this
    },
    Columns() {      //线索请求---------------------------------------------------------------------------------
      var that = this
        axios.get('/admin/customer/customer/statisticsColumns',{
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.leads_source = res.data.data.customer_source
                that.leads_worth = res.data.data.customer_worth
                that.leads_customer_tag = res.data.data.customer_tag
                that.leads_customer_service = res.data.data.customer_service       
            }
        })      
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl)
    },
    UP_Success2(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl2.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl2)
    },
    HAndemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl.push(element.response.data.url)
         
        }
        console.log(that.Row_Eurl)
    },
    HAndemove2(file, fileList) {
      var that = this
      that.Row_Eurl2 = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl2.push(element.response.data.url)
         
        }
        console.log(that.Row_Eurl)
    },
    
    Btn_cj(e) {         // 线索添加  确认
      var that = this
      if (e == 1) {
        axios({
          method: 'post',
          url: '/admin/customer/customer/add', 
          data: {
            row:{
              type: that.type_Self,
              username: that.valueA_input1,
              sex:that.valueA_input2,
              mobile: that.valueA_input3,
              weixin: that.valueA_input4,
              company: that.valueA_input5,
              expect_time:that.valueA_Months,
              source_id:that.valueA_1,                //来源
              tag_ids: that.valueA_sele1,               //标签ID
              goods_ids:that.valueA_sele2,               //需求项目
              worth_id:that.valueA_2,                      //客户价值ID
              service_id: that.valueA_input6,               //服务对象ID

              description:that.valueA_textarea,
              images:that.Row_Eurl,
            }
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
            console.log('qweqwe')
          }else {
            that.$message.success(res.data.msg);
            that.show1 = false
            that.AAAAA()  
          }
        
        })  
      } else if (e == 0) {
        axios({
          method: 'put',
          url: '/admin/customer/customer/edit', 
          data: {
            id:that.KH_ID,
            row:{
              type: that.type_Self,
              username: that.valueA_input1,
              sex:that.valueA_input2,
              mobile: that.valueA_input3,
              weixin: that.valueA_input4,
              company: that.valueA_input5,
              expect_time:that.valueA_Months,
              source_id:that.valueA_1,                //来源
              tag_ids: that.valueA_sele1,               //标签ID
              goods_ids:that.valueA_sele2,               //需求项目
              worth_id:that.valueA_2,                      //客户价值ID
              service_id: that.valueA_input6,               //服务对象ID

              description:that.valueA_textarea,
              
              images:that.Row_Eurl,
              // power:{
              //   staff_ids:that.valueA_4,
              //   department_ids:that.valueA_5,
              // }
            }
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
            console.log('qweqwe')
          }else {
            that.$message.success(res.data.msg);
            that.show1 = false
            that.AAAAA()  
          }
        
        })          
      }
     
    },

    handhange(e) {          // 勾选
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
              that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },

    HanDel(e, data) {       //单独删除
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
      }).then(() => {
            axios.delete('/admin/customer/customer/del',{ 
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                that.AAAAA()
            }
          })            
      })     
    },

    Add_Del(e) {             //批量操作
        var that = this
        var Att = that.multipleSelection
        if (e == 1) {         
          console.log(e)        

        } else if(e == 2) {           
          if (that.multipleSelection_id == null) {      //批量删除
            that.$message.warning('请勾选信息');
          } else {
            that.$confirm("确定要删除吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'delete',
              url: '/admin/customer/customer/del',
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                  that.$message.success(res.data.msg);
                  that.AAAAA()
                }
            }) 
          })
        }
        } else if( e == 4) {
          if (that.multipleSelection_id == null) {      //批量退回
            that.$message.warning('请勾选信息');
          } else {
            that.$confirm("确定要批量退回吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'post',
              url: '/admin/customer/customer/callback', 
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
              that.$message.success(res.data.msg);
              that.show1 = false
              that.AAAAA()        
            })  
          })
            
          }
        } else if (e == 5) {
          if (that.multipleSelection_id == null) {      //批量认领
            that.$message.warning('请勾选信息');
          } else {
            that.$confirm("确定要批量认领吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'post',
              url: '/admin/customer/customer/claim', 
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
              that.$message.success(res.data.msg);
              that.show1 = false
              that.AAAAA()        
            })  
          })
            
          }          
        }
    },

    HanEdit_SL(e, data) {              //单独认领
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要认领吗？", "提示", {
          type: "primary"
      }).then(() => {
        axios({
          method: 'post',
          url: '/admin/customer/customer/claim', 
          data: {
            ids:arr
          },
        }).then(function (res) {
          that.$message.success(res.data.msg);
          that.show1 = false
          that.AAAAA()        
        })             
      })     
    },

    Handlbc_TH(e, data) {            //点击退回
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要退回吗？", "提示", {
          type: "primary"
      }).then(() => {
        axios({
          method: 'post',
          url: '/admin/customer/customer/callback', 
          data: {
            ids:arr
          },
        }).then(function (res) {
          that.$message.success(res.data.msg);
          that.show1 = false
          that.AAAAA()        
        })             
      })  
    },
    Handmuiksd(e) {
      var that = this
      var att = []
      console.log(e) 
      console.log(that.Case_attse)
      for (let i = 0; i < that.Case_attse.length; i++) {
        const element = that.Case_attse[i];
        att.push(element[1])
      }
      that.valueA_5 = att
      console.log(att)
    },
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        return y + "-" + MM + "-" + d
    },
    AAAAA() {                                   ////////////////111111111111111111111111111111111111111111
      var that = this
        axios({
          method: 'post',
          url: '/admin/customer/customer/index', 
          data: {
            page: 1,
            offset:0,
            limit:6,
            type: that.type_Self,
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })  
    },
    BBBBB() {
      var that = this
        axios.get('/admin/customer/config/statisticsColumns',{   // 设置选项 
              params:{
                type: 'customer'
              }
          }).then(function (res) {
            if (res.data.code == 0) {
              alert('请求失败');  
            } else{ 
                  that.Config1 = res.data.data.claim_day
                  that.Config2 = res.data.data.claim_one
                  that.Config3 = res.data.data.flow_min
                  that.Config4 = res.data.data.export_week
                  that.Config5 = res.data.data.export_one
                  that.Config6 = res.data.data.claim_num
                
            }
        })  
    },
    handleCurrentChange1(val) {    // 分页 ···············································
      var that = this
      console.log(val)
      that.currentPage1 = val
        axios({
          method: 'post',
          url: '/admin/customer/customer/index',   
            params:{
              page: val,
              limit:6,
              type: that.type_Self,
            }

        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.tableData = res.data.data.rows
              that.total1 = res.data.data.total
            }

        }) 

    },
  },
};
</script>


<style scoped> 

.Manage_2 .elelels {
  width: 120px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}  
.Manage_2 .Rtt_im22g {
  width: 120px;
  height: auto; 
}

</style>
